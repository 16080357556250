<template>
    <CModal
      title="Add Work Order"
      :show.sync="myModal"
      :no-close-on-backdrop="true"
      :centered="true"
      size="sm"
      color="dark"
    >
      <template #header>
        <h6 class="modal-title">SEND REPORT</h6>
        <!-- <CButtonClose @click="darkModal = false" class="text-white"/> -->
      </template>
      <CRow>
            <CCol sm="12">
                <CInput
                    placeholder="Name"
                    v-model="form.name"
                />
                <CInput
                    placeholder="Email"
                    v-model="form.email"
                />
                <CTextarea
                    placeholder="Type your message here..."
                    horizontal
                    rows="6"
                    v-model="form.message"
                    class="textarea-fw"
                />
            </CCol>
        </CRow>
      <template #footer>
        <CButton @click="submit" color="success" class="branding-btn">Send</CButton>
        <CButton @click="myModal = false" color="danger">Cancel</CButton>
      </template>
    </CModal>
</template>
<script>
import axios from 'axios';

export default {
    data(){
        return {
            myModal: false,
            form: {
                email: '',
                name: '',
                message: '',
                data: ''
            },
            
        }
    },
    props: ['sendDataModal'],
    watch: {
        sendDataModal(newVal){
            console.log(newVal.data);
            this.form.data = newVal.data;
            this.myModal = true;
        }
    },
    methods: {
        emitEvent(event) {
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
                const docs = {
                    name: event.target.files[0].name,
                    size: event.target.files[0].size,
                    lastModifiedDate: event.target.files[0].lastModifiedDate,
                    base64: reader.result
                };
                this.form.file = docs;
                this.$emit('input', docs)
            };
        },
        submit(){
            this.$store.dispatch('mail/sendMail', this.form).then(response => {
                this.myModal = false;
                this.form = {
                    name: '',
                    email: '',
                    message: '',
                    data: ''
                }
            });
            
        },
    },
    created(){
        this.$store.dispatch('personel/fetchPersonel');
    }
}
</script>