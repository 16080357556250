<template>
    <CModal
      title="Edit Work Order"
      :show.sync="modalEditShow"
      :no-close-on-backdrop="true"
      :centered="true"
      size="lg"
      color="dark"
    >
      <template #header>
        <h6 class="modal-title">PERSONAL INFORMATION</h6>
      </template>
       <CRow>
        <CCol sm="7">
          <CRow class="mb-3">
            <CCol lg="8">
            <span v-if="form_edit">Membership Expiration: <b>{{form_edit.expiration_date}}</b></span>
            </CCol>
            <CCol lg="4">
            <span> W.O. #: <b>{{form_edit.work_order}}</b> </span>
            </CCol>
          </CRow>
          <CRow>
              <CCol lg="8">
                <div class="form-group" v-if="form_edit.member">
                  <label>MEMBER</label>
                  <v-select 
                      class="mb-3 custom-new-select"
                      v-model='form_edit.member_id'
                      :options="members | selectData" 
                      placeholder="Select Member"
                      :reduce="label => label.value" 
                      label="label"
                      :isValid="checkIfValid('member_id')"
                      :value.sync="$v.form_edit.member_id.$model"
                      :class="{ 'border-red': $v.form_edit.member_id.$anyError}"
                  />

                   <div v-if="$v.form_edit.member_id.$anyError == true" class="invalid-feedback" style="display:block; margin-top:-10px;">
                        Member is required!
                    </div>
                </div>
                <div v-else>
                  <CInput
                      v-model='form_edit.name'
                      label="NAME"
                      :isValid="checkIfValid('name')"
                      invalidFeedback="Name is required!"
                      :value.sync="$v.form_edit.name.$model"
                      autocomplete="given-name"
                  />
                </div>
              </CCol>
              <CCol lg="1" class="invisible">
                <CSwitch
                      class="mr-1"
                      color="primary"
                      :checked="form_edit.member"
                      @update:checked="switchChange"
                    />
              </CCol>
              <CCol lg="2" class="ml-3 invisible">
                <label>Member?</label>
              </CCol>
            </CRow>
            <CRow>
              <CCol>
                <div v-if="form_edit.member" class="form-group">
                  <label>VEHICLE</label>
                    <v-select 
                        class="mb-3 custom-new-select"
                        v-model='form_edit.property_id'
                        :options="vehicle_property | selectDataVehicleMember" 
                        :disabled="v_disabled"
                        placeholder="Select Vehicle"
                        :reduce="label => label.property_id" 
                        label="label"
                        :isValid="checkIfValid('property_id')"
                        :value.sync="$v.form_edit.property_id.$model"
                        :class="{ 'border-red': $v.form_edit.property_id.$anyError}"
                    />
                    <div v-if="$v.form_edit.property_id.$anyError == true" class="invalid-feedback" style="display:block; margin-top:-10px;">
                        Vehicle is required!
                    </div>
                  </div>
                  <div v-else class="form-group">
                    <label>VEHICLE</label>
                      <v-select 
                          class="mb-3 custom-new-select"
                          v-model='form_edit.vehicle_id'
                          :options="vehicle | selectDataVehicle" 
                          placeholder="Select Vehicle"
                          :error-messages="vehicleErrors"
                          :reduce="label => label.value" 
                          label="label"
                          required
                          solo
                          :class="{ 'border-red': $v.form_edit.vehicle_id.$anyError}"
                          :isValid="checkIfValid('vehicle_id')"
                          invalidFeedback="Vehicle is required!"
                          :value.sync="$v.form_edit.vehicle_id.$model"
                      >
                      </v-select>
                      <div v-if="$v.form_edit.vehicle_id.$anyError == true" class="invalid-feedback" style="display:block; margin-top:-10px;">
                          Vehicle is required!
                      </div>
                </div>
              </CCol>
            </CRow>
            <CInput
                v-if="!form_edit.member"
                label="PLATE NUMBER"
                v-model="form_edit.plate_no"
                :isValid="checkIfValid('plate_no')"
                invalidFeedback="Plate No. is required!"
                :value.sync="$v.form_edit.plate_no.$model"
            />
            <CInput
                label="READING ACTUAL ODOMETER"
                v-model="form_edit.odo"
                type="number"
                :isValid="checkIfValid('odo')"
                invalidFeedback="ODO is required!"
                :value.sync="$v.form_edit.odo.$model"
            />
            <v-date-picker v-if="form_edit.add_as_member" v-model="form_edit.birthdate" class="custom-date-container" mode="date" color="orange">
                <template v-slot="{ inputValue, inputEvents }">
                    <label class="v-date-picker-label"> BIRTH DATE </label>
                    <input
                    class="px-2 py-1 custom-date-picker border mb-3 rounded focus:outline-none focus:border-blue-300"
                    :value="inputValue"
                    v-on="inputEvents"
                    style="width: 100%"
                    />
                </template>
            </v-date-picker>
            <CInput v-if="form_edit.add_as_member"
                label="BENEFICIARY NAME"
                v-model="form_edit.beneficiary_name"
                :isValid="checkIfValid('beneficiary_name')"
                invalidFeedback="BENEFICIARY NAME is required!"
                :value.sync="$v.form_edit.beneficiary_name.$model"
            />
            <CInput v-if="form_edit.add_as_member"
                label="BENEFICIARY CONTACT NUMBER"
                v-model="form_edit.beneficiary_contact"
                :isValid="checkIfValid('beneficiary_contact')"
                invalidFeedback="BENEFICIARY CONTACT NUMBER is required!"
                :value.sync="$v.form_edit.beneficiary_contact.$model"
            />
            <CInput
                label="CONTACT NUMBER"
                placeholder="Ex. 093500000000"
                v-model="form_edit.contact_no"
                type="number"
                :isValid="checkIfValid('contact_no')"
                invalidFeedback="Contact No. is required!"
                :value.sync="$v.form_edit.contact_no.$model"
                v-if="!form_edit.member"
            />
            <v-date-picker v-model="form_edit.transaction_date" class="custom-date-container" mode="date" color="orange">
                <template v-slot="{ inputValue, inputEvents }">
                    <label class="v-date-picker-label"> TRANSACTION DATE </label>
                    <input
                    class="px-2 py-1 custom-date-picker border mb-3 rounded focus:outline-none focus:border-blue-300"
                    :value="inputValue"
                    v-on="inputEvents"
                    style="width: 100%"
                    />
                </template>
            </v-date-picker>
            <CRow>
                <CCol md="6" lg="6">
                    <CInput
                        v-if="form_edit.add_as_member || form_edit.member"
                        label="CARD NUMBER"
                        value='1'
                        v-model="form_edit.card_no"
                        disabled="true"
                    />
                </CCol>
                <CCol md="6" lg="6">
                    <CInput
                        v-if="form_edit.add_as_member"
                        label="CARD VALIDITY"
                        :placeholder='new Date() | cardValidityFilter'
                        disabled="true"
                    />
                </CCol>
            </CRow>
            
            <CRow v-if="!form_edit.member">
              <CCol lg="1">
                <CSwitch
                  class="mr-1"
                  color="primary"
                  :checked="form_edit.add_as_member"
                  @update:checked="switchChangeAddMember"
                />
              </CCol>
              <CCol lg="10" class="ml-3">
                Add as new Member?
              </CCol>
            </CRow>
        </CCol>
        <CCol sm="5">
          <div class="calculation">
              <h6>Summary of Purchase</h6>
              <span v-if="payment.membership_fee">MEMBERSHIP FEE: ₱{{payment.membership_fee}}<br></span>
              <span>SUB TOTAL: ₱{{payment.subtotal}}</span><br>
              <span>DISCOUNT: ₱{{payment.discount}}</span><br>
              <h4>TOTAL: ₱{{subtotal}}</h4>
              <CElementCover v-if="coverElement" :opacity="0.9"/>
          </div>

          <div v-if="form_edit.member_id" class="calculation mt-2">
              <div class="note_header" style="display:flex; justify-content: space-between;">
                <h6>Client Note</h6>
                <a @click="collapse_note = !collapse_note" style="cursor:pointer;"><CIcon name="cil-plus"/></a>
              </div>
              <CCollapse :show="collapse_note">
                  <CInput
                  placeholder="Type your note here..."
                  v-model="note_message"
                  >
                  <template #append>
                      <CButton v-on:click="addNote" class="branding-btn" color="primary">Add</CButton>
                  </template>
                  </CInput>
              </CCollapse>
              <div v-if="notes != ''">
                  <div v-for="note in notes" :key="note.id">
                      <div style="display:flex; width:100%; justify-content: space-between;">
                          <div>
                              {{note.message}}
                          </div>
                          <a class="hover-pointer" @click="deleteNote(note.id)"><CIcon class="hover-pointer text-danger" name="cil-trash"/></a>
                      </div>
                  </div>
              </div>
              <div v-else class="text-center">
                  Empty Note
              </div>
          </div>
          <div style="position:absolute; bottom:0; right:0; margin-right:15px;" v-for="redeem in vehicle_redeem" :key="redeem.id">
            <div class="redeem_free">
              <span>10 POINTS REWARD FOR {{redeem.name}} [{{redeem.plate_no}}]</span>
            </div>
          </div>
        </CCol>
      </CRow>
      <div class="divider" style="background:#636F83; padding-top:10px; display: flex; justify-content: space-between; padding-bottom:10px; color:#fff; width:104.2%; padding-left:15px; margin-left:-16px; margin-top:10px;">
        <h6 class="modal-title pt-1">SERVICE</h6> <CButton @click="collapse = !collapse" size="sm" class="branding-btn mr-3"><CIcon name="cil-plus"/></CButton>
      </div>
      <CCollapse :show="collapse">
        <CCard style="background:#fff; box-shadow:none;" body-wrapper>
            <CRow class="adding_services">
                <CCol lg="6" md="6">
                    <div class="form-group">
                    <label>SERVICE TYPE</label>
                      <v-select
                          class="custom-new-select"
                          placeholder="Select Service Type"
                          v-model="form_services.category_id"
                          :options="category | categoryFilter"
                          :isValid="checkIfServicesValid('category_id')"
                          :value.sync="$v.form_services.category_id.$model"
                          :class="{ 'border-red': $v.form_services.category_id.$anyError}"
                      />
                      <div v-if="$v.form_services.category_id.$anyError == true" class="invalid-feedback" style="display:block;">
                          Category is required!
                      </div>
                    </div>
                </CCol>
                <CCol lg="5" md="5" class="sm-mt-2">
                    <div class="form-group">
                      <label>SERVICES</label>
                      <v-select
                          class="custom-new-select"
                          placeholder="Services"
                          v-model="form_services.services_id"
                          :options="services | servicesFilter"
                          :isValid="checkIfServicesValid('services_id')"
                          :value.sync="$v.form_services.services_id.$model"
                          :class="{ 'border-red': $v.form_services.services_id.$anyError}"
                      />
                      <div v-if="$v.form_services.services_id.$anyError == true" class="invalid-feedback" style="display:block;">
                          Services is required!
                      </div>
                    </div>
                </CCol>
                <CCol lg="1" md="1">
                    <CButton @click="AddService" id="btn-add_services" size="sm" class="branding-btn mt-1 mln-14">ADD</CButton>
                </CCol>
            </CRow>
        </CCard>
      </CCollapse>
      <CRow>
        <CCol>
          <table class="modified-table mt-2">
            <tr>
              <th>Services Name</th>
              <th>Services Charge</th>
              <th>Action</th>
            </tr>
            <tr v-if="temp_edit_trans == ''">
                <td colspan="3">NO SELECTED SERVICES</td>
            </tr>
            <tr v-for="temp_tran in temp_edit_trans" :key="temp_tran.id">
              <td>
                {{temp_tran.name}}
              </td>
              <td>
                ₱{{temp_tran.price}}
              </td>
              <td>
                <CButton size="sm" @click="getServiceDelete(temp_tran)" color="danger"><CIcon name="cil-trash"/></CButton>
              </td>
            </tr>
          </table>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="submitForm" id="btn-update_transaction" color="success" class="branding-btn">UPDATE</CButton>
        <CButton @click="modalEditShow = false" color="danger">Cancel</CButton>
      </template>
    </CModal>
</template>
<script>
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

export default {
    name: 'EditModal',
    data(){
        return {
            collapse: false,
            modalEditShow: false,
            v_disabled: true,
            disabled_savebtn: false,
            collapse_note: false,
            note_message: '',
            vehicle_redeem: [],
            form_edit: this.getEmptyForm(),
            coverElement: false,
            payment: {
                subtotal: 0,
                total: 0,
                discount: 0,
                amount: 0,
                changed: 0,
                membership_fee: 0,
                renewal: 0,
                service_charge: 0
                
            },
            form_services: {
                category_id: '',
                services_id: ''
            },
            checkbox: {
                member: false,
                add_as_member: false
            }
        }
    },
    components: {
      vSelect
    },
    props: ['editModalData'],
    watch:{
        editModalData(newVal){
        this.payment.discount = newVal.data.payment.discounted;
        this.payment.total = newVal.data.payment.total;
        this.vehicle_redeem = [];
        this.$store.dispatch('discount/fetchDiscount');
        this.$store.dispatch('sales/checkFirstTransNull');
        this.$store.dispatch('category/fetchCategory');
        
        if(newVal.data.transaction_type == 'member'){
          this.form_edit.property_id = '';
            this.coverElement = true;
            this.get_property(newVal.data.member.id);
            // this.$store.dispatch('vehicle/findProperty', newVal.data.member.id).then(() => {
            
            // if(this.vehicle_property){
            //   var vehicle = [];
            //   for(let i = 0; i < this.vehicle_property.length; i++){
            //     if(this.vehicle_property[i].transactions){
            //       if(this.vehicle_property[i].transactions.length >= 10){
            //         vehicle.push({
            //           name: this.vehicle_property[i].vehicle.vehicle_name,
            //           plate_no: this.vehicle_property[i].plate_no,
            //           id: this.vehicle_property[i].id
            //         });
            //       }
            //     }
            //   }
            //   this.vehicle_redeem = vehicle;
            // }
            // this.totalCalculation();
            //   setTimeout(() => {  
            //   this.coverElement = false;
            //   clearInterval()}, 1000);
            // });
            if(newVal.data.member.status == 'Active'){
                this.$store.dispatch('member/fetchMembers');
                this.form_edit = {
                    member: true,
                    add_as_member: false,
                    id: newVal.data.id,
                    work_order: newVal.data.work_order,
                    card_no: newVal.data.member.card_no,
                    expiration_date: newVal.data.member.expiration_date,
                    member_id: newVal.data.member_id,
                    plate_no: newVal.data.plate_no,
                    property_id: newVal.data.property_id,
                    property: newVal.data.property,
                    vehicle_id: newVal.data.property.vehicle.id,
                    odo: newVal.data.odo,
                    branch_id: this.$store.getters["auth/user"].branch_id,
                    user_id: this.$store.getters["auth/user"].id,
                    transaction_date: newVal.data.transaction_date,
                };
                
            }else{
              this.$store.dispatch('vehicle/fetchVehicle');
                this.form_edit = {
                    member: false,
                    add_as_member: true,
                    id: newVal.data.id,
                    card_no: newVal.data.member.card_no,
                    work_order: newVal.data.work_order,
                    birthdate: new Date(newVal.data.member.birthdate),
                    name: newVal.data.name,
                    beneficiary_name: newVal.data.member.beneficiary_name,
                    beneficiary_contact: newVal.data.member.beneficiary_contact,
                    vehicle_size_id: newVal.data.property.vehicle.size_id,
                    vehicle_id: newVal.data.vehicle_id,
                    plate_no: newVal.data.plate_no,
                    odo: newVal.data.odo,
                    contact_no: newVal.data.contact_no,
                    branch_id: this.$store.getters["auth/user"].branch_id,
                    user_id: this.$store.getters["auth/user"].id,
                    transaction_date: newVal.data.transaction_date,
                };
            }
            this.totalCalculation();
        }else{
            this.$store.dispatch('vehicle/fetchVehicle');
            this.form_edit = {
                member: false,
                add_as_member: false,
                card_no: '',
                work_order: newVal.data.work_order,
                id: newVal.data.id,
                name: newVal.data.name,
                birthdate: new Date(),
                beneficiary_name: '',
                beneficiary_contact: '',
                vehicle_size_id: newVal.data.vehicle.size_id,
                vehicle_id: newVal.data.vehicle_id,
                plate_no: newVal.data.plate_no,
                odo: newVal.data.odo,
                contact_no: newVal.data.contact_no,
                branch_id: this.$store.getters["auth/user"].branch_id,
                user_id: this.$store.getters["auth/user"].id,
                transaction_date: newVal.data.transaction_date,
            };
        }
        
        this.modalEditShow = true;
          
        let temp_edit_trans = [];
        for (let i = 0; i < newVal.data.temp_trans.length; i++) {
            temp_edit_trans.push({name: newVal.data.temp_trans[i].variation.services.services_name, price: newVal.data.temp_trans[i].variation.price, variation_id: newVal.data.temp_trans[i].variation.id, services_id: newVal.data.temp_trans[i].variation.services_id});
        }

        if(this.form_edit.member_id){
          this.$store.dispatch('sales/checkFirstTrans', this.form_edit.member_id).then(() => {
            this.totalCalculation();
          });
        }

        this.$store.dispatch('sales/fetchTempEditTrans', temp_edit_trans);

        },
        temp_edit_trans(){
          this.totalCalculation();
        },
        subtotal(){
            this.payment.total = this.payment.subtotal - this.payment.discount;
        },
        category_id(){
            var data = [];
            this.form_services.services_id = '';
            if(this.form_edit.member == false){
                if(this.form_edit.vehicle_size_id){
                  data = {size_id:this.form_edit.vehicle_size_id, category_id: this.form_services.category_id};
                }
            }else{
                if(this.form_edit.property_id){
                  data = {size_id:this.form_edit.property.vehicle.size_id, category_id: this.form_services.category_id};
                }
            }

            if(data.category_id){
              this.$store.dispatch('services/findServices', data);
            }else{
              this.$store.dispatch('services/nullServices');
            }
        },
        member_id() {
            if(this.form_edit.member_id){
                
                this.get_property(this.form_edit.member_id);
                this.$store.dispatch('vehicle/findProperty', this.form_edit.member_id).then(() => {
                  
                  this.$store.dispatch('note/findNote', this.form_edit.member_id);
                  this.$store.dispatch('sales/fetchInfo', this.form_edit.member_id);
                  this.v_disabled = false;
                  if(this.vehicle_property){
                    var vehicle = [];
                    for(let i = 0; i < this.vehicle_property.length; i++){
                      if(this.vehicle_property[i].transactions){
                        if(this.vehicle_property[i].transactions.length >= 10){
                          vehicle.push({
                            name: this.vehicle_property[i].vehicle.vehicle_name,
                            plate_no: this.vehicle_property[i].plate_no,
                            id: this.vehicle_property[i].id
                          });
                        }
                      }
                    }
                    this.vehicle_redeem = vehicle;
                  }
                  
                  this.totalCalculation();
                });
                
            }else{
                this.$store.dispatch('sales/fetchInfo');
                this.form_services.category_id = '';
                this.form_services.services_id = '';

                this.vehicle_redeem = [];
                this.$store.dispatch('vehicle/fetchVehicle');
            }
            if(this.form_edit.member_id){
              this.$store.dispatch('sales/checkFirstTrans', this.form_edit.member_id).then(() => {
                this.totalCalculation();
              });
            }
        },
        property_id(){
            this.form_services.category_id = '';
            this.form_services.services_id = '';
            this.$store.dispatch('sales/tempTransNull');
        },
        vehicle_id(){
            this.$store.dispatch('size/findSize', this.form_edit.vehicle_id).then(() => {
                this.form_edit.vehicle_size_id = this.size_id;
            });
            this.form_services.category_id = '';
            this.form_services.services_id = '';
        },
        add_as_member(){
            if(this.form_edit.add_as_member == true){
              this.payment.membership_fee = 300;
              this.payment.subtotal = this.payment.subtotal + 300;
            }else{
              this.payment.membership_fee = 0;
              this.payment.subtotal = this.payment.subtotal - 300;
            }
        }
    },
    computed: {
      ...mapGetters({ 
          work_order: "sales/work_order",
          members: "member/members",
          vehicle: "vehicle/vehicles",
          info: "sales/info",
          size_id: 'size/size_id',
          new_member_no: "sales/new_member_no",
          notes: "note/notes",
          category: "category/category",
          services: "services/services",
          temp_trans: "sales/temp_trans",
          temp_edit_trans: "sales/temp_edit_trans",
          is_first_trans: "sales/is_first_trans",
          discounts: "discount/discount",
          vehicle_property: "vehicle/vehicle_property"
      }),
      formString () { return JSON.stringify(this.form_edit, null, 4) },
      isValid () { return !this.$v.form_edit.$invalid },
      isValidServices () { return !this.$v.form_services.$invalid },
      isDirty () { return this.$v.form_edit.$anyDirty },
      member_id() {
          return this.form_edit.member_id;
      },
      property_id(){
          return this.form_edit.property_id;
      },
      category_id(){
          return this.form_services.category_id;
      },
      vehicle_id(){
          return this.form_edit.vehicle_id;
      },
      add_as_member(){
          return this.form_edit.add_as_member;
      },
      subtotal(){
          return this.payment.subtotal - this.payment.discount;
      },
      membership_fee(){
          return this.payment.membership_fee;
      }
    },
    filters: {
      selectData: function (value) {
          if(value){
              var i;
              var members = [];
              for (i = 0; i < value.length; i++) {
                  if(value[i].name){
                      members.push({ 
                      value: value[i].id,
                      contact_no: value[i].contact_no,
                      address: value[i].address,
                      label: value[i].name
                      }); 
                  }
              }
              return members;
          }
      },
      
      servicesFilter: function (value) {
          if(value){
              var i;
              var services = [];
              for (i = 0; i < value.length; i++) {
                  if(value[i].variation[0].price){
                      services.push({ 
                      value: value[i].variation[0].id,
                      services_id: value[i].id,
                      price: value[i].variation[0].price,
                      name: value[i].services_name,
                      label: value[i].services_name + ' - ₱' + value[i].variation[0].price
                      }); 
                  }
              }
              return services;
          }
      },
      categoryFilter: function (value) {
          if(value){
              var i;
              var members = [];
              for (i = 0; i < value.length; i++) {
                  if(value[i].category_name){
                      members.push({ 
                      value: value[i].id,
                      label: value[i].category_name
                      }); 
                  }
              }
              return members;
          }
      },
      selectDataVehicle: function (value) {
          if(value){
              var i;
              var vehicles = [];
              for (i = 0; i < value.length; i++) {
                  if(value[i].vehicle_name){
                      vehicles.push({ 
                      value: value[i].id, 
                      size_id: value[i].size_id,
                      label: value[i].vehicle_name
                      }); 
                  }
              }
              return vehicles;

          }
      },
      selectDataVehicleMember: function (value) {
          if(value){
              var i;
              var vehicles = [];
              for (i = 0; i < value.length; i++) {
                  if(value[i].vehicle){
                      vehicles.push({ 
                      value: value[i].vehicle.id, 
                      plate_no: value[i].plate_no,
                      size_id: value[i].vehicle.size_id,
                      property_id: value[i].id,
                      label: value[i].vehicle.vehicle_name + ' [Plate #:' + value[i].plate_no + ']' + ' | points(' + value[i].transactions.length + ')'
                      }); 
                  }
              }
              return vehicles;
          }
      },
      cardNoFilter(data){
        if(data) {
          var count = 0;
          var split = data.split('-');
          var add = parseInt(split[2])+1;
          var digit = add.toString().length;
          if(digit == 1){
            count = '000' + add;
          }else if(digit == 2){
            count = '00' + add;
          }else if(digit == 3){
            count = '0' + add;
          }else{
            count = add + 1;
          }
        }
        return new Date().getFullYear() + '-' + '000-' + count;
      },
      cardValidityFilter(data){
        var year = data.getFullYear()+1;
        return year + '/' + data.getMonth() + '/' + data.getDate();
      },
    },
    mixins: [validationMixin],
    validations() {
        if (this.form_edit.add_as_member == false) {
            if(this.form_edit.member == true){
            return {
                form_edit: {
                        property_id: {
                            required
                        },
                        member_id: {
                            required
                        },
                        odo: {
                            required
                        },
                    },
                form_services: {
                        category_id: {
                          required
                        },
                        services_id: {
                          required
                        }
                    }
                }
            }else{
                return {
                    form_edit: {
                        name: {
                            required
                        },
                        vehicle_id: {
                            required
                        },
                        plate_no: {
                            required
                        },
                        odo: {
                            required
                        },
                        contact_no: {
                            required
                        },
                    },
                    form_services: {
                        category_id: {
                          required
                        },
                        services_id: {
                          required
                        }
                    }
                }
            }
        } else {
            if(this.form_edit.member == true){
            return {
                form_edit: {
                        property_id: {
                            required
                        },
                        member_id: {
                            required
                        },
                        odo: {
                            required
                        },
                    },
                    form_services: {
                        category_id: {
                          required
                        },
                        services_id: {
                          required
                        }
                    }
                }
            }else{
                return {
                form_edit: {
                        name: {
                            required
                        },
                        birthdate: {
                            required
                        },
                        beneficiary_name: {
                            required
                        },
                        beneficiary_contact: {
                            required
                        },
                        vehicle_id: {
                            required
                        },
                        plate_no: {
                            required
                        },
                        odo: {
                            required
                        },
                        contact_no: {
                            required
                        },
                    },
                    form_services: {
                        category_id: {
                          required
                        },
                        services_id: {
                          required
                        }
                    }
                }
            }
        }
    },
    methods: {
      checkIfValid (fieldName) {
          const field = this.$v.form_edit[fieldName]
          if (!field.$dirty) {
              return null
          } 
          return !(field.$invalid || field.$model === '')
      },
      checkIfServicesValid(fieldName){
          const field = this.$v.form_services[fieldName]
          if (!field.$dirty) {
              return null
          } 
          return !(field.$invalid || field.$model === '')
      },
      validate () {
          this.$v.form_edit.$touch()
      },
      validateServices () {
          this.$v.form_services.$touch()
      },
      submitForm(){
        const params = {
                payment: this.payment,
                services: this.temp_edit_trans,
                data: this.form_edit,
        }
        this.validate();
        if (this.isValid) {
          this.$root.btn_load(true, 'btn-update_transaction', 'UPDATE');
            if(params.services != ''){
            this.$store.dispatch('sales/submitUpdateServices', params).then(response => {
                this.$root.btn_load(false, 'btn-update_transaction', 'UPDATE');
                this.modalEditShow = false;
                this.$v.form_edit.$reset();
            });
            }else{
                this.$root.btn_load(false, 'btn-update_transaction', 'UPDATE');
                this.$store.dispatch('notification/addNotification', {
                type: 'danger',
                message: 'Empty Services!'
                }, {root: true});
            }
        }
      },
      vehicleErrors () {
          if (!this.$v.form_edit.vehicle_id.required &&  this.$v.form_edit.vehicle_id.$dirty) {
              return 'This field is required'
          } else return []
      },
      addNote(){
          const params = {
              member_id: this.form_edit.member_id,
              message: this.note_message,
          }
          this.$store.dispatch('note/addNote', params);
          this.note_message = '';
      },
      deleteNote(id){
          const params = {
              member_id: this.form_edit.member_id.value,
              id: id,
          }
          this.$store.dispatch('note/deleteNote', params);
      },
      switchChange(value){
          if(value == true){
              this.v_disabled = true;
              this.form_edit.member = true;
              this.$store.dispatch('member/fetchMembers');
              this.form_edit.add_as_member = false;
              this.form_edit.member_id = '';
          }else {
              this.$store.dispatch('vehicle/fetchVehicle');
              this.form_edit.member = false;
              this.form_edit.add_as_member = false;
              this.form_edit.member_id = '';
              this.form_edit.vehicle_id = '';
          }
          
      },
      switchChangeAddMember(value){
          if(value == true){
            this.form_edit.add_as_member = true;
            this.$store.dispatch('sales/getNewMemberNoNull');
            this.$store.dispatch('sales/getNewMemberNo').then(() => {
              this.form_edit.card_no = this.new_member_no;
            });
            this.totalCalculation();
          }else {
            this.form_edit.add_as_member = false;
            this.$store.dispatch('sales/getNewMemberNoNull');
            this.payment.discount = 0;
            this.$store.dispatch('sales/removeServices', 0);
            this.totalCalculation();
          }
      },
      totalCalculation(){
        let discount = 0;
        let subtotal = 0;

        this.temp_edit_trans.forEach(temp_trans => {
          this.discounts.forEach(item => {
            if(this.form_edit.add_as_member){
              if(item.discount_type == 'Regular Membership Discount'){
                if(item.services_id == temp_trans.services_id){
                  if(item.percentage_type == '%'){
                    let divide = item.discount_percentage / 100;
                    discount += temp_trans.price * divide;
                  }else{
                    discount += item.discount_percentage;
                  }
                }
              }
            }
            if(item.discount_type == 'Regular Membership Discount'){
              if(this.form_edit.member_id){
                console.log('Regular Membership Discount');
                if(item.services_id == temp_trans.services_id){
                  if(item.percentage_type == '%'){
                    let divide = item.discount_percentage / 100;
                    discount += temp_trans.price * divide;
                  }else{
                    discount += item.discount_percentage;
                  }
                }
              }
            } else if(item.discount_type == 'First Wash Discount'){
              if(this.is_first_trans || this.form_edit.add_as_member){
                console.log('First Wash Discount');
                if(item.services_id == temp_trans.services_id){
                  if(item.percentage_type == '%'){
                    let divide = item.discount_percentage / 100;
                    discount += temp_trans.price * divide;
                  }else{
                    discount += item.discount_percentage;
                  }
                }
              }
            } else if(item.discount_type == '10 points Discount'){
              if(this.vehicle_redeem != ''){
                console.log('10 points Discount');
                if(item.services_id == temp_trans.services_id){
                  if(item.percentage_type == '%'){
                    let divide = item.discount_percentage / 100;
                    discount += temp_trans.price * divide;
                  }else{
                    discount += item.discount_percentage;
                  }
                }
              }
            }
            
          })
          subtotal += temp_trans.price;
        })
        
          this.payment.discount = discount;
          
          if(this.payment.membership_fee > 0){
            this.payment.subtotal = subtotal + 300;
          }else{
            this.payment.subtotal = subtotal;
          }
      },
      AddAsNewMemberService(){
          const params = {
              name: 'Membership Fee',
              price: 300,
              services_id: 0
          }
          this.$store.dispatch('sales/addServices',params);
      },
      AddService() {   
          this.validateServices();
          if(this.isValidServices){

            this.$root.btn_load(true, 'btn-add_services', 'ADD');
            
            const params = {
                name: this.form_services.services_id.name,
                price: this.form_services.services_id.price,
                variation_id: this.form_services.services_id.value,
                services_id: this.form_services.services_id.services_id
            }

            this.$store.dispatch('sales/addEditServices', params).then(() => {
                this.$root.btn_load(false, 'btn-add_services', 'ADD');
                this.form_services.category_id = '';
                this.form_services.services_id = '';
                this.$v.form_services.$reset();
            });
          }    

      },
      getServiceDelete(data) {
          if(data.services_id == 0){
              this.form_edit.add_as_member = false;
          }
          this.temp_edit_trans.splice(this.temp_edit_trans.indexOf(data), 1);
      },
      getEmptyForm () {
          return {
              member: false,
              add_as_member: false,
              id: '',
              work_order: '',
              member_id: '',
              card_no: '',
              vehicle_size_id: '',
              vehicle_id: '',
              plate_no: '',
              property_id: '',
              name: '',
              odo: '',
              contact_no: '',
              beneficiary_name: '',
              beneficiary_contact: '',
              branch_id: this.$store.getters["auth/user"].branch_id,
              user_id: this.$store.getters["auth/user"].id,
              date: new Date(),
          }
      },
      get_property(id){
        this.$store.dispatch('vehicle/findProperty', id).then(() => {
            
        if(this.vehicle_property){
          var vehicle = [];
          for(let i = 0; i < this.vehicle_property.length; i++){
            if(this.vehicle_property[i].transactions){
              if(this.vehicle_property[i].transactions.length >= 10){
                vehicle.push({
                  name: this.vehicle_property[i].vehicle.vehicle_name,
                  plate_no: this.vehicle_property[i].plate_no,
                  id: this.vehicle_property[i].id
                });
              }
            }
          }
          this.vehicle_redeem = vehicle;
        }
        this.totalCalculation();
          setTimeout(() => {  
          this.coverElement = false;
          clearInterval()}, 1000);
        });
      }
    },
    created(){
    }
}
</script>