<template>
    <div>
      <CDataTable
        :hover="hover"
        :striped="striped"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 10"
        :dark="dark"
        :table-filter="true"
        pagination
        items-per-page-select
        :tableFilter='{ placeholder: "Search", label: " "}'
      >
        <template #date="{item}">
          <td>
            {{item.transaction_date}}
          </td>
        </template>
        <template #name="{item}">
          <td>
              <span v-if="item.transaction_type == 'renewal'">
                <span v-if="item.member">{{item.member.name}}</span>
              </span>
              <span v-if="item.transaction_type == 'member'">
                <span v-if="item.member">{{item.member.name}}</span>
              </span>
              <span v-else>
                <span v-if="item.name">{{item.name}}</span>
              </span>
          </td>
        </template>
        <template #car_model="{item}">
          <td v-if="item.transaction_type == 'member'">
            <span v-if="item.property">{{item.property.vehicle.vehicle_name}}</span>
          </td>
          <td v-else>
            <span v-if="item.vehicle">
            {{item.vehicle.vehicle_name}}
            </span>
          </td>
        </template>
        <template #work_order="{item}">
          <td>
            {{item.work_order}}
          </td>
        </template>
        <template #card_no="{item}">
          <td v-if="item.member">
            {{item.member.card_no}}
          </td>
          <td v-else>
            &nbsp;
          </td>
        </template>
        <template #transaction_type="{item}">
          <td v-if="item.transaction_type == 'member'">
             <CBadge :color="getBadge('Active')">{{item.transaction_type}}</CBadge>
          </td>
          <td v-else>
             <CBadge :color="getBadge('Inactive')">{{item.transaction_type}}</CBadge>
          </td>
        </template>
        <template #amount="{item}">
          <td>
            ₱{{item.payment.total}}
          </td>
        </template>
        <template #encoded_by="{item}">
          <td>
            <span v-if="item.user">{{item.user.name}}</span>
          </td>
        </template>
        <template #services="{item}">
          <td v-if="item.temp_trans">
            <span v-for="items in item.temp_trans" :key="items.id">
              {{items.variation.services.services_name}}
            </span>
          </td>
        </template>
        
        <template #action="{item}">
            <td>
                <CButtonGroup>
                  <div style="position:relative;" v-if="$ability.can('update', 'KjFFdIK') && item.transaction_type != 'renewal'">
                    <CButton size="sm" @click="getValue(item)" color="info"><CIcon name="cil-pencil"/></CButton>
                    <RoleModal v-if="$store.state.permission.permission_settings" elementKey="KjFFdIK" customClass="tableBtn" actionKey="update" :keyPage="$route.name"/>
                  </div>
                  <div style="position:relative;" v-if="$ability.can('delete', 'l8kWZQR')">
                    <CButton size="sm" @click="getValueDel(item)" color="danger"><CIcon name="cil-trash"/></CButton>
                    <RoleModal v-if="$store.state.permission.permission_settings" elementKey="l8kWZQR" customClass="tableBtn" actionKey="delete" :keyPage="$route.name"/>
                  </div>
                </CButtonGroup>
            </td>
        </template>
      </CDataTable>
    </div>
</template>

<script>
import moment from 'moment';
import RoleModal from "@/containers/RoleModal"

export default {
  components:{
    RoleModal
  },
  name: 'Table',
  props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return ['date', 'work_order', 'card_no', 'name', 'contact_no', 'car_model', 'services', 'plate_no', 'transaction_type', 'amount', 'encoded_by', 'action']
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  filters: {
    countID(value){
          var count = 0;
          if(value != null){
            var add = value+1;
            var digit = value.toString().length;
            if(digit == 1){
              count = '000' + add;
            }else if(digit == 2){
              count = '00' + add;
            }else if(digit == 3){
              count = '0' + add;
            }else{
              count = value + 1;
            }
          }

          return count;
      },
      formatDate(value){
         if (value) {
          return moment(String(value)).format('YYYY') + '-'
        }
      },
      formatDateTable(value){
         if (value) {
          return moment(String(value)).format('YYYY/MM/DD')
        }
      }
  },
  methods: {
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    getValue(data){
      this.$emit('event_child', data, 'edit');
    },
    getValueDel(data){
      this.$emit('event_child', data, 'delete');
    },
  },
}
</script>
